import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { FaPhoneVolume } from "react-icons/fa";
import UserWayAccessibilityMenu from "../components/UserWayAccessibilityMenu";
import "../css/header.css";

function UpperHead() {
  return (
    <React.Fragment>
      <div className="sticky-header">
        <div className="nav-logo">
          <Image className="marco_logo" alt="Marco Logo" src={process.env.REACT_APP_THUMBNAIL_IMAGE} />
        </div>

        <Navbar expand="lg" variant="light" className="fixated sm-width">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className="w-100">
            <Nav className="justify-content-center mr-auto">
              <Nav.Link as={Link} to="/">
                <strong>Home</strong>
              </Nav.Link>
              <Nav.Link as={Link} to="/About">
                <strong>About Us</strong>
              </Nav.Link>
              <Nav.Link as={Link} to="/Contact">
                <strong>Contact Us</strong>
              </Nav.Link>
              <a
                className="nav-link"
                href={process.env.REACT_APP_CDN + "Blank_Rental_Application.pdf"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Download Application</strong>
              </a>
            </Nav>
            <Nav className=" justify-content-end" style={{ whiteSpace: "nowrap" }}>
              <strong>
                <FaPhoneVolume /> CALL TODAY! <a href="tel:+18669244732">(866) 924-4732</a>
              </strong>
            </Nav>
          </Navbar.Collapse>
          <div className="desktop-AccessibilityMenu-show">
            <UserWayAccessibilityMenu color="black" width={40} height={40}/>
          </div>
          </Navbar>
          <div className="mobile-AccessibilityMenu-show">
        <UserWayAccessibilityMenu color="black" width={40} height={40}/>
        </div>
      </div>
      <p className="text-center text_blue_XL">
        <strong style={{ fontSize: "30px" }}>Great Homes. Low Rents.</strong>
      </p>
    </React.Fragment>
  );
}

export default UpperHead;
