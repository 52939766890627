import React from "react";
import Classes from "../css/privacy.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import "../css/table.css";
export default function Privacy() {
  return (
    <React.Fragment>
      <Container className="bg-text sm-width">
        <Row>
          <Col className="text-left">
            <div className="animate__animated animate__fadeIn">
              <div className={Classes.pageContainer}>
                <div style={{ textAlign: "right", color: "#bcbcbc" }}>
                  <p>Rev. 08/23</p>
                </div>
                <div className="mt-1">
                  <h1>Our Security and Privacy Policy</h1>
                </div>
                <div className="mt-3">
                  <h3>FACTS</h3>
                  <div>
                    WHAT DOES MARCO MANAGEMENT, LLC. DO WITH YOUR PERSONAL
                    INFORMATION?
                  </div>
                </div>
                <Row className="mt-3 flex-column flex-md-row">
                  <Col>
                    <h3>Why?</h3>
                    <div>
                      Financial companies choose how they share your personal
                      information. Federal law gives consumers the right to
                      limit some but not all sharing. Federal law also requires
                      us to tell you how we collect, share, and protect your
                      personal information. Please read this notice carefully to
                      understand what we do.
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3">
                    <h3>What?</h3>
                    <div>
                      The types of personal information we collect and share
                      depend on the product or service you have with us. This
                      information can include:
                      <ul>
                        <li> Social Security number and income</li>
                        <li>Account balances and payment history</li>
                        <li>Credit scores and employment information</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <div className="mt-1">
                  <h3>How?</h3>
                  <div>
                    All financial companies need to share customers' personal
                    information to run their everyday business. In the section
                    below, we list the reasons financial companies can share
                    their customers' personal information; the reasons Marco
                    Management, LLC. chooses to share; and whether you can limit
                    this sharing.
                  </div>
                </div>
                <Table
                  responsive
                  className={`${Classes.reason_table} table table-borderless mt-3`}
                >
                  <thead>
                    <tr>
                      <th scope="col">
                        Reasons we can share your personal information
                      </th>
                      <th scope="col">Does Marco Management share?</th>
                      <th scope="col">Can you limit this sharing?</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <b>For our everyday business purposes</b> -<br />
                        such as to process your transactions, maintain your
                        account(s), respond to court orders and legal
                        investigations, or report to credit bureaus
                      </th>
                      <td>Yes</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <b>For our marketing purposes</b> -<br />
                        to offer our products and services to you
                      </th>
                      <td>Yes</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <b>
                          For joint marketing with other financial companies
                        </b>
                      </th>
                      <td>Yes</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <b>For our affiliates' everyday business purposes</b> -
                        <br />
                        information about your transactions and experiences
                      </th>
                      <td>Yes</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <b>For our affiliates' everyday business purposes</b> -
                        <br />
                        information about your creditworthiness
                      </th>
                      <td>Yes</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <b>For our affiliates to market to you</b>
                      </th>
                      <td>Yes</td>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <b>For non affiliates to market to you</b>
                      </th>
                      <td>Yes</td>
                      <td>Yes</td>
                    </tr>
                  </tbody>
                </Table>

                <div className="mt-3">
                  <h3>To limit our sharing</h3>
                  <div>
                    <ul>
                      <li>
                        Call <a href="tel:+18669244732">(866) 924-4732</a>{" "}
                        <b>or</b>
                      </li>
                      <li>
                        Mail the <b>form</b> below <br />(
                        <a
                          href={
                            process.env.REACT_APP_CDN + "Non-State+-+English+Form.pdf"
                          }
                          rel="noreferrer"
                          target="_blank"
                        >
                          Click here
                        </a>{" "}
                        to download the form).
                      </li>
                    </ul>{" "}
                    <b>Please note</b>: If you are a new customer, we can begin
                    sharing your information 30 days from the date we sent this
                    notice. When you are no longer our customer, we can continue
                    to share your information as described in this notice for up
                    to 18 months after you are no longer our customer. <br />
                    <br />
                    However, you can contact us at any time to limit our
                    sharing.
                  </div>
                </div>

                <div className="mt-3">
                  <h3>Questions?</h3>
                  <div>
                    Call <a href="tel:+18669244732">(866) 924-4732</a>
                  </div>
                </div>

                <div className="d-flex flex-column align-items-center mt-3">
                  <div>
                    <Col md={5} className={Classes.section_blue_line}></Col>
                    <h1 className="text-center">What We Do</h1>
                  </div>
                </div>
                <Row className="mt-3 align-items-center">
                  <Col>
                    <h3>
                      How does Marco Management protect my personal information?
                    </h3>
                    <div>
                      To protect your personal information from unauthorized
                      access and use, we use security measures that comply with
                      Federal law. These measures include computer safeguards
                      and secured files and buildings. <br /> Marco Management
                      employees are trained in our privacy policy and
                      procedures. Only employees who have a legitimate business
                      reason to access your personal information may do so.
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3 align-items-center">
                  <Col>
                    <h3>
                      How does Marco Management collect my personal information?
                    </h3>
                    <div>
                      We collect your personal information, for example, when
                      you{" "}
                      <ul>
                        {" "}
                        <li>
                          {" "}
                          apply with us or give us your contact information
                        </li>{" "}
                        <li>
                          give us your income information or provide employment
                          information
                        </li>{" "}
                        <li> show us your driver's license</li>
                      </ul>{" "}
                      We also collect your personal information from others,
                      such as credit bureaus, affiliates, or other companies.
                    </div>
                  </Col>
                </Row>

                <Row className=" mt-3 align-items-center">
                  <Col>
                    <h3>Why can't I limit all sharing?</h3>
                    <div>
                      Federal law gives you the right to limit only{" "}
                      <ul>
                        <li>
                          sharing for affiliates' everyday business purposes -
                          information about your creditworthiness
                        </li>{" "}
                        <li>
                          affiliates from using your information to market to
                          you
                        </li>{" "}
                        <li>sharing for non affiliates to market to you</li>
                      </ul>{" "}
                      State laws and individual companies may give you
                      additional rights to limit sharing.
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3 align-items-center">
                  <Col>
                    <h3>
                      What happens when I limit sharing for an account I hold
                      jointly with someone else?
                    </h3>
                    <div>
                      Your choices will apply to everyone on your account -
                      unless you tell us otherwise.
                    </div>
                  </Col>
                </Row>
                <Row className=" mt-3 align-items-center">
                  <Col>
                    <h3>Phone Number Collection and Communication</h3>
                    <div className="mb-2">
                      We collect phone numbers as a part of our data collection
                      process, and we utilize texting and calling as the form of
                      communication. Your phone number may be used for various
                      communication purposes, such as sending important updates,
                      promotions, account verification, or providing customer
                      support.
                    </div>
                    <ul>
                      <li className="mb-2">
                        <strong style={{ display: "block" }}>
                          How to Opt-Out from Text Messages:{" "}
                        </strong>
                        You can cancel the SMS service at any time by replying{" "}
                        <strong>'STOP'</strong> to the number from which you
                        receive texts. Upon sending the message{" "}
                        <strong>'STOP'</strong>, we will confirm your
                        unsubscribe request, and you will no longer receive SMS
                        messages from us. If you want to rejoin, simply reach
                        out to us at{" "}
                        <a
                          href="tel:+18669244732"
                          target="_blank"
                          rel="noreferrer"
                        >
                          (866) 924-4732
                        </a>
                        .
                      </li>{" "}
                      <li>
                        <strong style={{ display: "block" }}>
                          How to Opt-Out from Calls/Voicemails:{" "}
                        </strong>
                        To opt out of our calls, just send a message with the
                        word <strong>'STOP'</strong> to the same number from
                        which you receive calls. We will acknowledge your
                        request, and you will no longer receive our calls. If
                        you want to rejoin, simply reach out to us at{" "}
                        <a href="tel:+18669244732">(866) 924-4732</a>.
                      </li>{" "}
                    </ul>{" "}
                  </Col>
                </Row>

                <div className="d-flex flex-column align-items-center mt-3">
                  <div>
                    <Col md={5} className={Classes.section_blue_line}></Col>
                    <h1 className="text-center">Definitions</h1>
                  </div>
                </div>

                <Row className="mt-3 align-items-center">
                  <Col>
                    <h3>Affiliates</h3>
                    <div>
                      Companies related by common ownership or control. They can
                      be financial and nonfinancial companies.
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3 align-items-center">
                  <Col>
                    <h3>Non Affiliates</h3>
                    <div>
                      Companies not related by common ownership or control. They
                      can be financial and nonfinancial companies.{" "}
                      <ul>
                        <li>
                          <i>
                            Our non affiliates we share with can include
                            financial services companies such as mortgage
                            brokers, lenders, and servicers.
                          </i>{" "}
                        </li>
                      </ul>{" "}
                    </div>
                    <h3 className="mt-3 mt-md-1">Joint marketing</h3>
                    <div>
                      A formal agreement between non affiliated financial
                      companies that together market financial products or
                      services to you.{" "}
                      <ul>
                        <li>
                          <i>
                            Our joint marketing partners include financial
                            services companies such as mortgage brokers,
                            lenders, and servicers.
                          </i>
                        </li>
                      </ul>{" "}
                    </div>
                  </Col>
                </Row>

                <div className="my-5">
                  <h3>Where can I get more information?</h3>
                  <div>
                    Call toll-free,{" "}
                    <a href="tel:+18669244732">(866) 924-4732</a>.
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
