import React from "react";
import { Container } from "react-bootstrap";
import GetProperty from "../includes/GetProperty";

function Property({ match }) {
    const {
        params: {PropertyId}
    } = match;

    return(
        <React.Fragment>
            <Container className="fixated" fluid={true}>
                <GetProperty PropertyId={PropertyId} /><br/>
            </Container>
        </React.Fragment>
    );
}

export default Property;