import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Nav,
  Card,
  Spinner,
} from "react-bootstrap";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Contact.css";

//  Custom CSS
const treasury = {
  opacity: 0,
  position: "absolute",
};
// End of Custom CSS

function serialize(form) {
  let inputs = form.elements;
  let testArray = {};
  for (let i = 0; i < inputs.length; i++) {
    testArray[inputs[i].id] = inputs[i].value;
  }
  return testArray;
}
function trim(theStr) {
  return theStr.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
}
function getNumbers(inVal, isDouble, removeCharsFromPrefix) {
  var retVal = "";
  var okChars = "0123456789";
  var tmpVal = trim(inVal);
  var curChar = "";
  var isDotFound = false;

  if (tmpVal !== "") {
    for (var i = 0; i < tmpVal.length; i++) {
      curChar = tmpVal.charAt(i);

      if (okChars.indexOf(curChar) !== -1) {
        if (!isDotFound && curChar === "." && isDouble) {
          isDotFound = true;
          retVal = retVal + ".";
        }

        if (retVal === "" && removeCharsFromPrefix.indexOf(curChar) !== -1) {
          //Do nothing
        } else {
          retVal += curChar;
        }
      }
    }
  }

  return retVal;
}

function Contact() {
  const [validated, setValidated] = useState(false);

  const [showTY, setTYModal] = useState(false);
  const [showFail, setFailModal] = useState(false);
  const [showLoading, setLoading] = useState(false);
  const [consent, setConsent] = useState(true);
  const [contactName, setContactName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [zipcodeError, setZipCodeError] = useState("");
  const [city, setCity] = useState("");
  const [cityOptions, setCityOptions] = useState("");
  const [state, setState] = useState("");
  const [stateList, setStateList] = useState("");
  const [statesOptions, setStatesOptions] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  let propertyState = "";
  const handleTelephoneNumber = (evt) => {
    var tmpVal = trim(getNumbers(evt.currentTarget.value, false, "01"));
    var FirstThreeDigit = tmpVal.substring(0, 3);
    var FirstSixDigit = tmpVal.substring(0, 6);

    /* Below Pattern will check numbers between below ranges
        456, 555, 900,
        100, 101, ..., 199
        211, 311, ..., 911  ***811 removed
        370, 371, ..., 379
        960, 961, ..., 969
        800, 801, ..., 855  ***removed
        866, 877, 888, 800855
        */
    var patt =
      /^(1[0-9][0-9])|([2-7]11)|(911)|(37[0-9])|(96[0-9])|(456)|(555)|(866)|(877)|(888)|(900)$/;
    var patt2 = /^800855$/;
    if (patt.test(FirstThreeDigit)) {
      evt.currentTarget.value = "";
      return;
    } else if (patt2.test(FirstSixDigit)) {
      evt.currentTarget.value = "";
      return;
    }

    var curChar = "";

    var areaCode = "";
    var firstThree = "";
    var lastFour = "";
    var extension = "";

    if (tmpVal !== "") {
      for (var i = 0; i < tmpVal.length; i++) {
        curChar = tmpVal.charAt(i);

        if (i < 3) areaCode += curChar;
        if (i < 6 && i > 2) firstThree += curChar;
        if (i < 10 && i > 5) lastFour += curChar;
        if (i > 9) extension += curChar;
      }
    }

    var retVal = "";

    if (areaCode !== "") retVal += "(" + areaCode + ")";

    if (firstThree !== "") {
      retVal += " " + firstThree;
      if (firstThree.length === 3) retVal += "-";
    }

    if (lastFour !== "") retVal += lastFour;

    if (extension !== "") retVal += " x" + extension;

    setPhoneNumber(retVal);
  };

  function updateZipCode(propZipCode) {
    setZipCode(propZipCode);

    if (propZipCode.length === 5) {
      axios
        .get(process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE + propZipCode, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          propertyState = stateList.filter(
            (state) => state.code === response.data.state
          );
          if (!propertyState || !propertyState.length) {
            setZipCodeError(
              `We are not accepting applications for the state of ${response.data.state}`
            );
          } else {
            setState(response.data.state);
            setCityOptions(
              response.data.cities.map((item, index) => (
                <option key={index} value={item.code}>
                  {item.name}
                </option>
              ))
            );
          }
        })
        .catch((e) => {
          setZipCodeError(e.response.data.error);
        });
    }

    if (propZipCode.length < 5) {
      setZipCodeError("");
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setLoading(true);
      const data = document.querySelector("#contactForm");
      const getData = serialize(data);
      axios
        .post(
          `https://kk9ynwj5wg.execute-api.us-east-1.amazonaws.com/master/`,
          getData
        )
        .then((res) => {
          if (res.data.statusCode === 200) {
            let submitNotes = document.getElementById("contactForm");
            submitNotes.classList.remove("was-validated");
            document.getElementById("contactForm").reset();
            setPhoneNumber("");
            setTYModal(true);
          } else {
            setFailModal(true);
          }
          setLoading(false);
        })
        .catch(function (error) {
          setFailModal(true);
        });
    }
    setValidated(true);
  };

  useEffect(() => {
    // Get state from public endpoint
    axios
      .post(
        process.env.REACT_APP_SUNSOFT_RESTSERVER_STATE_LIST,
        { domain: "https://sunwestmortgage.com/", companyCode: "SUNWST000" },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        // Set States list
        setStateList(response.data);
        // Set the dropdown state list option.

        setStatesOptions(
          response.data.map((item, index) => (
            <option key={index} value={item.code}>
              {item.name}
            </option>
          ))
        );
      });

    /* Get client IP and geolocation(maxmind API) Task LRTV-3 */
    axios
      .post(
        process.env.REACT_APP_MAXMIND,
        {},
        {
          headers: { "Content-Type": "application/json" },
        },
        { timeout: 5000 }
      )
      .then((response) => {
        if (
          response.data !== "IP Not Available" &&
          response.data !== "Invalid Country"
        ) {
          let zipcode = response.data.zipcode;

          if (zipcode) {
            axios
              .get(
                process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE + zipcode,
                { timeout: 5000 },
                {
                  headers: { "Content-Type": "application/json" },
                }
              )
              .then((response) => {
                setState(response.data.state);
                setCityOptions(
                  response.data.cities.map((item, index) => (
                    <option key={index} value={item.code}>
                      {item.name}
                    </option>
                  ))
                );
              });
          }
        } else {
          console.log(response.data); // Show Error message from lambda function
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <React.Fragment>
      <div style={{ backgroundColor: "white" }}>
        <Container className="bg-text">
          <p className="text-left">
            <strong>
              Marco Management, LLC. stands by our reputation of providing
              excellent customer service.
            </strong>
            <br />
            <br />
            Please fill out the quick form below and a Marco Management
            representative will get back to you shortly.
            <br />
            <br />
            <strong>Contact Form</strong>
          </p>
          <p className="text-center small" style={{ color: "#990000" }}>
            * = Required Fields
          </p>
          <Form
            id="contactForm"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Row style={treasury} className="d-none">
              <Form.Group as={Col} sm={6} controlId="frca_name">
                <strong>
                  <Col sm={2}>&nbsp;</Col>
                  <Form.Label>* Rent Home Name: </Form.Label>
                </strong>
                <Form.Control
                  type="text"
                  minLength="2"
                  maxLength="12"
                  data-error="Please fill out this field correctly."
                />
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm={6} controlId="frca_code">
                <strong>
                  <Col sm={2}>&nbsp;</Col>
                  <Form.Label>* Rent Code: </Form.Label>
                </strong>
                <Form.Control
                  type="text"
                  pattern="^[0-9a-zA-Z]{4}$"
                  maxLength="4"
                  data-error="Please fill out this field correctly."
                />
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Form.Group as={Row} controlId="frcName">
              <Col sm={2}>&nbsp;</Col>
              <Form.Label column sm={2}>
                <strong>Name: * </strong>
              </Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Please enter your Name"
                  pattern="^[a-zA-Z0-9-._'\s][a-zA-Z0-9-._'\s]+$"
                  onChange={(event) =>
                    setContactName(event.currentTarget.value)
                  }
                  autoComplete="off"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please fill out the field correctly
                </Form.Control.Feedback>
              </Col>
              <Col sm={2}>
                &nbsp;
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="frcPhone">
              <Col sm={2}>&nbsp;</Col>
              <Form.Label column sm={2}>
                <strong>Telephone: * </strong>
              </Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Please enter your Telephone Number"
                  minLength={14}
                  maxLength={21}
                  pattern="^\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})[ ]*(( |x){1}[ ]?([0-9]){1,7}){0,1}$"
                  onChange={handleTelephoneNumber}
                  autoComplete="off"
                  value={PhoneNumber}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please fill out the field correctly
                </Form.Control.Feedback>
              </Col>
              <Col sm={2}>&nbsp;</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="frcEmailAdd">
              <Col sm={2}>&nbsp;</Col>
              <Form.Label column sm={2}>
                <strong>E-Mail Address: * </strong>
              </Form.Label>
              <Col>
                <Form.Control
                  type="Email"
                  placeholder="Please enter your Email Address"
                  pattern="^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9\-]+(?:\.[a-zA-Z0-9\-]{2,64})$"
                  autoComplete="off"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please fill out the field correctly
                </Form.Control.Feedback>
              </Col>
              <Col sm={2}>&nbsp;</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="frcAddress">
              <Col sm={2}>&nbsp;</Col>
              <Form.Label column sm={2}>
                <strong>Address: </strong>
              </Form.Label>
              <Col>
                <Form.Control type="text" autoComplete="off" />
                <Form.Control.Feedback type="invalid">
                  Please fill out the field correctly
                </Form.Control.Feedback>
              </Col>
              <Col sm={2}>&nbsp;</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="frczipcode">
              <Col sm={2}>&nbsp;</Col>
              <Form.Label column sm={2}>
                <strong>Zip Code: </strong>
              </Form.Label>
              <Col>
                <NumberFormat
                  className="form-control"
                  placeholder="Zip Code"
                  format="#####"
                  customInput={Form.Control}
                  value={zipCode}
                  displayType={"input"}
                  thousandSeparator={false}
                  fixedDecimalScale={true}
                  onValueChange={(values) => {
                    updateZipCode(values.value);
                  }}
                />
                {zipcodeError ? (
                  <p className="text-danger mb-0">{zipcodeError}</p>
                ) : null}
              </Col>
              <Col sm={2}>&nbsp;</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="frcCity">
              <Col sm={2}>&nbsp;</Col>
              <Form.Label column sm={2}>
                <strong>City: </strong>
              </Form.Label>
              <Col>
                <Form.Select 
                  value={city}
                  placeholder="Property City"
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                >
                  {cityOptions}
                </Form.Select>
              </Col>
              <Col sm={2}>&nbsp;</Col>
            </Form.Group>
            <Form.Group as={Row} controlId="frcState">
              <Col sm={2}>&nbsp;</Col>
              <Form.Label column sm={2}>
                <strong>State: * </strong>
              </Form.Label>
              <Col>
                <Form.Select 
                  value={state}
                  placeholder="Property State"
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                >
                  <option value="">Your State</option>
                  {statesOptions}
                </Form.Select>
              </Col>
              <Col sm={2}>&nbsp;</Col>
            </Form.Group>

            <Form.Group as={Row} controlId="frcMessage">
              <Col sm={2}>&nbsp;</Col>
              <Form.Label column sm={2}>
                <strong>Message: * </strong>
              </Form.Label>
              <Col>
                <Form.Control
                  as="textarea"
                  rows={3}
                  minLength={10}
                  placeholder="Please enter your message"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please lengthen this text to 10 characters or more
                </Form.Control.Feedback>
              </Col>
              <Col sm={2}>&nbsp;</Col>
            </Form.Group>

            <Row className="text-justify">
              <p className="pb-0 mb-1"> 
                <strong>Phone Solicitation Consent</strong>
                <br />
                By pressing Submit, you also agree to Marco Management, LLC's
                Terms and Conditions and Security and Privacy Policy, which you
                may access by clicking on those terms. By communicating with
                Marco Management, LLC. by phone, you consent to phone calls
                being recorded and monitored.
                <br />
                <br />
                By clicking I CONSENT below, you expressly consent to Marco
                Management, LLC. making (or allowing to be made on its behalf) a
                phone call, text message, or voicemail transmission to the phone
                number you entered above for the purpose of soliciting your
                purchase of consumer goods or services (including using an
                automated system for the selection or dialing of phone numbers,
                the playing of a recorded message when a connection is made, or
                the transmission of a pre-recorded voicemail), even if that
                phone number is a on a national, state, or internal Do Not Call
                Registry. You are not required to click on I CONSENT below
                (directly or indirectly) or agree to receive such phone calls,
                text messages, or voicemail transmissions as a condition of
                obtaining any goods or services from Marco Management, LLC. or
                its affiliates.
                <br />
                <br />
                You can reply “STOP” to the same number to opt-out anytime
              </p>

              <Form.Group as={Col} controlId="frcConsent">
                <div
                  className="d-flex justify-content-start align-items-center"
                  onClick={() => setConsent(true)}
                  style={{ cursor: "pointer", width: "fit-content" }}
                >
                  <Form.Check
                    type="checkbox"
                    checked={consent === true}
                    value={consent ? "I Consent" : "I Do Not Consent"}
                    readOnly
                  />
                  I Consent
                </div>
                <div
                  style={{ cursor: "pointer", width: "fit-content" }}
                  className="d-flex justify-content-start align-items-center"
                  onClick={() => setConsent(false)}
                >
                  <Form.Check
                    checked={consent === false}
                    type="checkbox"
                    value={consent ? "I Consent" : "I Do Not Consent"}
                    readOnly
                  />
                  I Do Not Consent
                </div>
              </Form.Group>
            </Row>
            {showLoading ? (
              <Row className="text-center">
                <Form.Group as={Col}>
                  <Button variant="secondary" className="mt-3" disabled>
                    <span>
                      <Spinner
                        animation="border"
                        variant="warning"
                        role="status"
                      />
                    </span>{" "}
                    Please Wait...
                  </Button>
                </Form.Group>
              </Row>
            ) : (
              <Form.Group as={Row}>
                <Col className="text-center">
                  <Button type="submit" variant="outline-info" size="lg">
                    &nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;
                  </Button>
                </Col>
              </Form.Group>
            )}
          </Form>
        </Container>
      </div>

      <Modal centered show={showTY} backdrop="static">
        <Modal.Body>
          <Card>
            <Card.Body>
              <Card.Text className="text-justify">
                Thank you <strong>{contactName}</strong>.<br />
                <br />
                We have received your request and will contact within 24 Hours.
                <br />
                <br />
                <Nav.Link as={Link} to="/" style={{ display: "contents" }}>
                  Click here to return to Home Page
                </Nav.Link>
              </Card.Text>
            </Card.Body>
            <Card.Footer className="text-right">
              <Button
                variant="info"
                onClick={() => {
                  setTYModal(false);
                  setValidated(false);
                }}
              >
                Close
              </Button>
            </Card.Footer>
          </Card>
        </Modal.Body>
      </Modal>
      <Modal
        size="sm"
        centered
        show={showFail}
        onHide={() => setFailModal(false)}
        backdrop="static"
      >
        <Modal.Body>
          Due to technical difficulties your request could not be sent. Please
          contact <a href="mailto:info@forrentca.com">info@forrentca.com</a>.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setFailModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Contact;
