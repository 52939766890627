import React, { Component } from "react";
import { Container, Row, Col, Card, Figure, Nav, Modal, Carousel, Image } from "react-bootstrap";
import { FaBath, FaAngleRight } from "react-icons/fa";
import { MdHome } from "react-icons/md";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import bedIcon from "../images/bed-icon.svg";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            propertyData: [],
            showGallery: false
        };
    }

    componentDidMount() {
        const propertiesJson = process.env.REACT_APP_CDN + "properties.json";
        fetch(propertiesJson)
            .then(response => response.json())
            .then(
                // handle the result
                (result) => {
                    this.setState({
                        isLoaded: true,
                        propertyData: result
                    });
                }
            )
    }

    render() {
        const setCloseGallery = () => {
            this.setState({ showGallery: false })
        }
        let propertyAvailable = true;
        const { propertyData } = this.state;
        const propListing = propertyData.map((Property) => {
            if (Property.prop_visible !== 0) {
                propertyAvailable = false;
                let address = (Property.prop_location.address === "" ? "" : Property.prop_location.address) +
                    (Property.prop_location.city === "" ? "" : ", " + Property.prop_location.city) +
                    (Property.prop_location.state === "" ? "" : ", " + Property.prop_location.state) +
                    (Property.prop_location.zipcode === "" ? "" : " " + Property.prop_location.zipcode);
                let title = formatter.format(Property.prop_rent) + " / Month - " + address;

                return <Card key={Property.prop_id} border="warning" className="fixated">
                    <div>
                        <Row>
                            <Col sm={4} className="text-center">
                                <Figure>
                                    <Figure.Image width={300} className="img-thumbnail" src={process.env.REACT_APP_CDN + "properties/" + (Property.prop_titlePhoto)} />
                                    <Figure.Caption className="gallery_hyperlink"><u><strong onClick={() => {
                                        this.setState({ showGallery: (Property.prop_id) })
                                    }}>More photos available</strong></u></Figure.Caption>
                                </Figure>
                            </Col>
                            <Col>
                                <p><strong>{title}</strong></p>
                                <p>
                                    <Image src={bedIcon} height="16px" width="16px" /> {Property.prop_amenities.bedroom} Bed&nbsp;
                                    <FaBath /> {Property.prop_amenities.bathroom} Bath
                                    {Property.prop_amenities.area !== "" ? " " : " "}{Property.prop_amenities.area !== "" ? <MdHome /> : null}{Property.prop_amenities.area !== "" ? Property.prop_amenities.area + " SQ. FT. " : null}
                                    - {Property.prop_name}</p>
                                <p>{parse(Property.prop_descp)}</p><br />
                                <Nav.Link as={Link} to={`/Property/${Property.prop_id}`} className="right-bottom"><strong>See Details <FaAngleRight /></strong></Nav.Link>
                            </Col>
                        </Row>
                    </div>
                </Card>
            }
        });
        if (propertyAvailable) {
            return <Card border="warning" className="fixated">
                <div className="jumbotron">
                    <Row>
                        <Col className="text-center">
                            <h2>Coming soon...</h2>
                        </Col>
                    </Row>
                </div>
            </Card>
        }

        const getModal = propertyData.map((Property) => {
            if (Property.prop_id === this.state.showGallery) {
                return <Modal show={this.state.showGallery} onHide={setCloseGallery} size="lg">
                    <Modal.Header>
                        <button type="button" className="close" onClick={() => setCloseGallery(false)}>
                            (<span aria-hidden="true">&times;</span>)Close
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <Carousel>
                            <Carousel.Item>
                                <img className="d-block w-100 h-auto" src={process.env.REACT_APP_CDN + "properties/" + (Property.prop_titlePhoto)} alt="" />
                            </Carousel.Item>
                            {Property.prop_listOfPhotos.map((item, key) =>
                                <Carousel.Item>
                                    <img key={item.id} className="d-block w-100 h-auto" src={process.env.REACT_APP_CDN + "properties/" + (item)} alt="" />
                                </Carousel.Item>)}
                        </Carousel>
                    </Modal.Body>
                </Modal>;
            }
        });
        return (
            <React.Fragment>
                <Container fluid={true}>
                    {propListing}
                </Container>
                {getModal}
            </React.Fragment>
        );
    }
}

export default Home;