import React, { Component } from "react";
import { Carousel, Card, Row, Col, Modal, Image } from "react-bootstrap";
import { FaBath, FaStream, FaTree, FaRegHospital, FaSchool, FaSwimmingPool } from "react-icons/fa";
import { GiHomeGarage } from "react-icons/gi";
import { MdLocalMall, MdTrain, MdSchool, MdLocalGroceryStore } from "react-icons/md";
import { IoIosRestaurant } from "react-icons/io";
import ReqInfoForm from "../components/ReqInfoForm";
import parse from "html-react-parser";
import bedIcon from "../images/bed-icon.svg";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
});

class GetProperty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            propertyData: [],
            showGallery: false
        };
    }

    componentDidMount() {
        const propertiesJson = process.env.REACT_APP_CDN + "properties.json";
        fetch(propertiesJson)
            .then(response => response.json())
            .then(
                // handle the result
                (result) => {
                    this.setState({
                        isLoaded: true,
                        propertyData: result
                    });
                }
            )
    }

    render() {
        const setCloseGallery = () => {
            this.setState({ showGallery: false })
        }
        const setShowGallery = () => {
            this.setState({ showGallery: true })
        }
        const { propertyData } = this.state;
        let propertyAvailable = true;
        const wholeInfo = propertyData.map(Properties => {
            let propId = Properties.prop_id;
            if ((String(propId) === decodeURIComponent(this.props.PropertyId)) && (Properties.prop_visible !== 0)) {
                propertyAvailable = false;
                let address = (Properties.prop_location.address === "" ? "" : Properties.prop_location.address) +
                    (Properties.prop_location.city === "" ? "" : ", " + Properties.prop_location.city) +
                    (Properties.prop_location.state === "" ? "" : ", " + Properties.prop_location.state) +
                    (Properties.prop_location.zipcode === "" ? "" : " " + Properties.prop_location.zipcode);
                let washAmenities = (Properties.prop_amenities.bedroom === "" ? "" : Properties.prop_amenities.bedroom) + " Bed, " +
                    (Properties.prop_amenities.bathroom === "" ? "" : Properties.prop_amenities.bathroom) + ' Bath '
                return <div key={Properties.prop_id}>
                    {/* Title head of Property Description page */}
                    <div style={{ backgroundColor: "#b2b2b2", padding: "20px" }}>
                        <p><strong className="text_blue_XL">{address}</strong></p>
                        <strong>{formatter.format(Properties.prop_rent)} / Month
                            | {washAmenities}
                            | {formatter.format(Properties.prop_deposit)} Deposit</strong>
                    </div><br />
                    {/* Information on Property Description page */}
                    <Row>
                        <Col sm={8}>
                            <Row>
                                <Col sm={8}>
                                    <Row>
                                        <Col>
                                            <Carousel>
                                                <Carousel.Item>
                                                    <img className="d-block w-100" src={process.env.REACT_APP_CDN + "properties/" + (Properties.prop_titlePhoto)} alt="" />
                                                </Carousel.Item>
                                                {Properties.prop_listOfPhotos.map((item, key) =>
                                                    <Carousel.Item>
                                                        <img key={key} className="d-block w-100" src={process.env.REACT_APP_CDN + "properties/" + (item)} alt="" />
                                                    </Carousel.Item>)}
                                            </Carousel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-center gallery_hyperlink"><u onClick={() => setShowGallery(true)}>View Gallery</u></Col>
                                    </Row>
                                </Col>
                                <Col sm={4}>
                                    <Card.Header>
                                        <div className="text_blue_XL">{formatter.format(Properties.prop_rent)} / Month</div>
                                        <p className="text_blue_XL" style={{ fontSize: "inherit" }}>*Rental Property</p>
                                        <p>
                                            {washAmenities}<br />
                                            {formatter.format(Properties.prop_deposit)} Deposit
                                        </p>
                                    </Card.Header>
                                </Col>
                            </Row><br />
                            <Card.Title>Rental Property Description</Card.Title>
                            <Card.Text>{parse(Properties.prop_descp)}</Card.Text>
                            <Card.Title>Rental Property Features &amp; Amenities</Card.Title>
                            <Card.Text>
                                {Properties.prop_amenities.bedroom !== "" ? <Image src={bedIcon} height="24px" width="24px" /> : null} {Properties.prop_amenities.bedroom !== "" ? Properties.prop_amenities.bedroom + " Bed" : null}{Properties.prop_amenities.bedroom !== "" ? <br /> : null}{/* <FaBath/> {Properties.prop_amenities.bathroom} Bath<br/> */}
                                {Properties.prop_amenities.bathroom !== "" ? <FaBath style={{ height: "1.5em", width: "1.5em" }} /> : null} {Properties.prop_amenities.bathroom !== "" ? Properties.prop_amenities.bathroom + " Bath" : null}{Properties.prop_amenities.bathroom !== "" ? <br /> : null}
                                {Properties.prop_amenities.swimmingPool !== "" ? <FaSwimmingPool style={{ height: "1.5em", width: "1.5em" }} /> : null} {Properties.prop_amenities.swimmingPool !== "" ? Properties.prop_amenities.swimmingPool : null}{Properties.prop_amenities.swimmingPool !== "" ? <br /> : null}
                                {Properties.prop_amenities.garage !== "" ? <GiHomeGarage style={{ height: "1.5em", width: "1.5em" }} /> : null} {Properties.prop_amenities.garage !== "" ? Properties.prop_amenities.garage + " Car Garage" : null}{Properties.prop_amenities.garage !== "" ? <br /> : null}
                                {Properties.prop_amenities.park !== "" ? <FaTree style={{ height: "1.5em", width: "1.5em" }} /> : null} {Properties.prop_amenities.park !== "" ? Properties.prop_amenities.park : null}{Properties.prop_amenities.park !== "" ? <br /> : null}
                                {Properties.prop_amenities.groceryStore !== "" ? <MdLocalGroceryStore style={{ height: "1.5em", width: "1.5em" }} /> : null} {Properties.prop_amenities.groceryStore !== "" ? Properties.prop_amenities.groceryStore : null}{Properties.prop_amenities.groceryStore !== "" ? <br /> : null}
                                {Properties.prop_amenities.malls !== "" ? <MdLocalMall style={{ height: "1.5em", width: "1.5em" }} /> : null} {Properties.prop_amenities.malls !== "" ? Properties.prop_amenities.malls : null}{Properties.prop_amenities.malls !== "" ? <br /> : null}
                                {Properties.prop_amenities.restaurants !== "" ? <IoIosRestaurant style={{ height: "1.5em", width: "1.5em" }} /> : null} {Properties.prop_amenities.restaurants !== "" ? Properties.prop_amenities.restaurants : null}{Properties.prop_amenities.restaurants !== "" ? <br /> : null}
                                {Properties.prop_amenities.hospital !== "" ? <FaRegHospital style={{ height: "1.5em", width: "1.5em" }} /> : null} {Properties.prop_amenities.hospital !== "" ? Properties.prop_amenities.hospital : null}{Properties.prop_amenities.hospital !== "" ? <br /> : null}
                                {Properties.prop_amenities.school !== "" ? <FaSchool style={{ height: "1.5em", width: "1.5em" }} /> : null} {Properties.prop_amenities.school !== "" ? Properties.prop_amenities.school : null}{Properties.prop_amenities.school !== "" ? <br /> : null}
                                {Properties.prop_amenities.highSchool !== "" ? <MdSchool style={{ height: "1.5em", width: "1.5em" }} /> : null} {Properties.prop_amenities.highSchool !== "" ? Properties.prop_amenities.highSchool : null}{Properties.prop_amenities.highSchool !== "" ? <br /> : null}
                                {Properties.prop_amenities.trainStation !== "" ? <MdTrain style={{ height: "1.5em", width: "1.5em" }} /> : null} {Properties.prop_amenities.trainStation !== "" ? Properties.prop_amenities.trainStation : null}{Properties.prop_amenities.trainStation !== "" ? <br /> : null}
                                {Properties.prop_amenities.other !== "" ? <FaStream style={{ height: "1.5em", width: "1.5em" }} /> : null} {Properties.prop_amenities.other !== "" ? "Other Important Features: " : null}{Properties.prop_amenities.other !== "" ? parse(Properties.prop_amenities.other) : null}
                                {/* <FaStream/> {Properties.prop_amenities.other} */}
                            </Card.Text>
                        </Col>
                        <ReqInfoForm propId={this.props.PropertyId} propInfo={propertyData} />
                    </Row><br />
                    {/* Address Map of Property Description page */}
                    <Row style={{ height: "300px" }}>
                        <Col>
                            <iframe src={Properties.prop_location.mapURL} alt="" style={{ width: "100%", height: "100%" }} title={Properties.prop_location.address} />
                        </Col>
                    </Row>
                    {/* Modal to show property images */}
                    <Modal show={this.state.showGallery} onHide={setCloseGallery} size="lg">
                        <Modal.Header>
                            <button type="button" className="close" onClick={() => setCloseGallery(false)}>
                                (<span aria-hidden="true">&times;</span>)Close
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100 h-auto" src={process.env.REACT_APP_CDN + "properties/" + (Properties.prop_titlePhoto)} alt="" />
                                </Carousel.Item>
                                {Properties.prop_listOfPhotos.map((item, key) =>
                                    <Carousel.Item>
                                        <img key={item.id} className="d-block w-100 h-auto" src={process.env.REACT_APP_CDN + "properties/" + (item)} alt="" />
                                    </Carousel.Item>)}
                            </Carousel>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        });
        if (propertyAvailable) {
            return <Card border="warning" className="fixated">
                <div className="jumbotron">
                    <Row>
                        <Col className="text-center">
                            <h3>Currently not available!</h3>
                        </Col>
                    </Row>
                </div>
            </Card>
        }

        return <React.Fragment>
            {wholeInfo}
        </React.Fragment>
    }
}

export default GetProperty;