import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function About() {
  return (
    <React.Fragment>
      <Container className="bg-text">
        <Row>
          <Col className="text-left">
            <h3>
              <strong>About Us</strong>
            </h3>
            <p>
              We are a property management company based in Cerritos,
              California, with over 10 years of experience in the industry. We
              manage more than 200 properties across California, ranging from
              single-family homes to multi-unit complexes.
            </p>
            <p>
              Our mission is to provide exceptional service to our clients and
              tenants, while maximizing the value and performance of their real
              estate assets. We handle all aspects of property management,
              including marketing, leasing, maintenance, accounting, legal
              compliance, and tenant relations. We use cutting-edge technology
              and software to streamline our operations and communication, and
              we adhere to the highest standards of professionalism and ethics.
            </p>
            <p>
              Whether you are a property owner looking for a reliable and
              trustworthy partner, or a tenant looking for a comfortable and
              affordable place to live, we are here to help you. Contact us
              today and let us show you why we are the best choice for your
              property management needs.
            </p>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default About;
