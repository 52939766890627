import React, { useState } from "react";
import { Row, Col, Card, Form, Button, Modal, Nav, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";

//  Custom CSS
const treasury = {
    opacity: 0,
    position: 'absolute'
}
// End of Custom CSS

function serialize(form) {
    let inputs = form.elements;
    let testArray = {};
    for(let i=0; i < inputs.length; i++) {
        testArray[(inputs[i].id)] = inputs[i].value;
    }
    return (testArray);
}
function trim(theStr) {
	return theStr.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
}
function getNumbers(inVal, isDouble, removeCharsFromPrefix) {		
    var retVal = "";
    var okChars = "0123456789";
    var tmpVal = trim(inVal);
    var curChar = "";
    var isDotFound = false;
    
    if (tmpVal !== "") {
        for (var i = 0; i < tmpVal.length; i++) {
            curChar = tmpVal.charAt(i);
            
            if (okChars.indexOf(curChar) !==-1) {					
                if (!isDotFound && curChar === "." && isDouble) {
                    isDotFound = true;
                    retVal = retVal + ".";
                }
                
                if (retVal === "" && removeCharsFromPrefix.indexOf(curChar) !== -1) {
                    //Do nothing
                } else {
                    retVal += curChar;
                }
            }
        }
    }
    
    return retVal;
}

function ReqInfoForm(props) {
    const [validated, setValidated] = useState(false);

    const[showTY, setTYModal] = useState(false)
    const[showFail, setFailModal] = useState(false)
    const [showLoading, setLoading] = useState(false)
    
    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [telephoneNumber, setTelephoneNumber] = useState("")
    const handleTelephoneNumber = evt => {
        var tmpVal = trim(getNumbers(evt.currentTarget.value, false, "01"));
        var FirstThreeDigit= tmpVal.substring(0, 3);
        var FirstSixDigit= tmpVal.substring(0, 6);

        /* Below Pattern will check numbers between below ranges
        456, 555, 900,
        100, 101, ..., 199
        211, 311, ..., 911  ***811 removed
        370, 371, ..., 379
        960, 961, ..., 969
        800, 801, ..., 855  ***removed
        866, 877, 888, 800855
        */
        var patt =/^(1[0-9][0-9])|([2-7]11)|(911)|(37[0-9])|(96[0-9])|(456)|(555)|(866)|(877)|(888)|(900)$/;
        var patt2 = /^800855$/;
        if(patt.test(FirstThreeDigit)) {
            evt.currentTarget.value="";
            return;
        } else if(patt2.test(FirstSixDigit)) {
            evt.currentTarget.value="";
            return;
        }

        var curChar = "";
        
        var areaCode = "";
        var firstThree = "";
        var lastFour = "";
        var extension = "";
        
        if (tmpVal !=="") {
            for (var i = 0; i < tmpVal.length; i++) {
                curChar = tmpVal.charAt(i);
                
                if (i < 3) areaCode += curChar;
                if (i < 6 && i > 2) firstThree += curChar;
                if (i < 10 && i > 5) lastFour += curChar;
                if (i > 9) extension += curChar;
            }
        }
        
        var retVal = "";
        
        if (areaCode !=="") retVal += "(" + areaCode + ")";	
            
        if (firstThree !=="") {
            retVal += " " + firstThree;
            if (firstThree.length === 3) retVal += "-";
        }
        
        if (lastFour !=="") retVal += lastFour;		
        
        if (extension !=="") retVal += " x" + extension;
        
        setTelephoneNumber(retVal);
    };

    const handleSubmit = event => {
        event.preventDefault();
        const form = event.currentTarget;
        
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setLoading(true);
            const data = document.querySelector('#requestForm');
            const getData = serialize(data);
            axios.post(`https://m79b1iinnd.execute-api.us-east-1.amazonaws.com/master/`, getData)
                .then(res =>  {
                    if (res.data.statusCode === 200) {
                        let submitNotes = document.getElementById("requestForm");
                        submitNotes.classList.remove("was-validated");
                        document.getElementById("requestForm").reset();
                        setTelephoneNumber("");
                        setTYModal(true);
                    } else {
                        setFailModal(true);
                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setFailModal(true);
                })
        }
        setValidated(true);
    }
    
    //  Variable to set up Title head of Property Description page
    const title_summary = props.propInfo.map(Properties => {
        let propId = Properties.prop_id;
        if( String(propId) === decodeURIComponent(props.propId)) {
            return <Row key={Properties.prop_id} hidden={true}>
                <input name="propertyID" id="propertyID" value={props.propId} readOnly />
                <input name="pAddress" id="pAddress" value={Properties.prop_location.address} readOnly />
                <input name="pCity" id="pCity" value={Properties.prop_location.city} readOnly />
                <input name="pState" id="pState" value={Properties.prop_location.state} readOnly />
                <input name="pZipCode" id="pZipCode" value={Properties.prop_location.zipcode} readOnly />
                <input name="pRent" id="pRent" value={Properties.prop_rent} readOnly />
                <input name="pBed" id="pBed" value={Properties.prop_amenities.bedroom===""?0:Properties.prop_amenities.bedroom} readOnly />
                <input name="pBath" id="pBath" value={Properties.prop_amenities.bathroom===""?0:Properties.prop_amenities.bathroom} readOnly />
                <input name="pSqFt" id="pSqFt" value={Properties.prop_amenities.area} readOnly />
                <input name="pYearBuilt" id="pYearBuilt" value={Properties.prop_yearBuilt} readOnly />
                <input name="pURL" id="pURL" value={window.location.href} readOnly />
            </Row>
        }
    });
    return(
        <React.Fragment>
            <Col sm={4} className="reqInfo">
                <Card.Header><h3>Request Information</h3></Card.Header>
                <Form id="requestForm" noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row style={treasury} className="d-none">
                        <Form.Group as={Col} sm={6} controlId="propRequest_name">
                            <strong><Form.Label>* Request Name: </Form.Label></strong>
                            <Form.Control type="text" minLength="2" maxLength="12" data-error="Please fill out this field correctly." />
                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} sm={6} controlId="propRequest_code">
                            <strong><Form.Label>* Request Code: </Form.Label></strong>
                            <Form.Control type="text" pattern="^[0-9a-zA-Z]{4}$" maxLength="4" data-error="Please fill out this field correctly." />
                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} sm={6} controlId="first_name">
                            <strong><Form.Label>First Name: *</Form.Label></strong>
                            <Form.Control type="text" data-error="Please fill out this field correctly." pattern="^[a-zA-Z0-9-._'\s]+$" onChange={event => setFirstName(event.currentTarget.value)} autoComplete="off" required />
                            <Form.Control.Feedback type="invalid">Please fill out the field correctly.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} sm={6} controlId="last_name">
                            <strong><Form.Label>Last Name: *</Form.Label></strong>
                            <Form.Control type="text" data-error="Please fill out this field correctly." pattern="^[a-zA-Z0-9-._'\s]+$" onChange={event => setLastName(event.currentTarget.value)} autoComplete="off" required />
                            <Form.Control.Feedback type="invalid">Please fill out the field correctly.</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="reqEmailAdd">
                            <Form.Label><strong>E-Mail Address: * </strong></Form.Label>
                            <Form.Control type="text" placeholder="Please enter your Email Address" pattern="^([A-Za-z0-9_\-\.])+@([A-Za-z0-9_\-])+.\.([A-Za-z]{2,4})$" autoComplete="off" required />
                            <Form.Control.Feedback type="invalid">Please fill out the field correctly</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="reqPhone">
                            <Form.Label sm={2}><strong>Telephone Number: * </strong></Form.Label>
                            <Form.Control type="tel" minLength={14} maxLength={21} pattern="^\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})[ ]*(( |x){1}[ ]?([0-9]){1,7}){0,1}$" onChange={handleTelephoneNumber} autoComplete="off" value={telephoneNumber} required />
                            <Form.Control.Feedback type="invalid">Please fill out the field correctly</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="reqMessage">
                            <Form.Label sm={2}><strong>Questions or Comments: * </strong></Form.Label>
                            <Form.Control as="textarea" rows={3} minLength={10} placeholder="Please enter your message" required />
                            <Form.Control.Feedback type="invalid">Please lengthen this text to 10 characters or more</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    {title_summary}
                    { showLoading ? <Row className="text-center">
                        <Form.Group as={Col}>
                            <Button variant="secondary" className="mt-3" disabled><span><Spinner animation="border" variant="warning" role="status" /></span> Please Wait...</Button>
                        </Form.Group>
                    </Row> :
                    <Row className="mt-2">
                        <Form.Group as={Col} className="text-center">
                            <Button type="submit" variant="outline-info" size="lg">&nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;</Button>
                        </Form.Group>
                    </Row> }
                </Form>
            </Col>
            
            <Modal centered show={showTY} backdrop="static">
                <Modal.Body>
                    <Card>
                        <Card.Body>
                            <Card.Text className="text-justify">
                                Thank you <strong>{first_name+" "+last_name}</strong>.<br/><br/>
                                We have received your request and will contact within 24 Hours.<br/><br/>
                                <Nav.Link as={Link} to="/" style={{display:"contents"}}>Click here to return to Home Page</Nav.Link>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-right">
                            <Button variant="info" onClick={() => setTYModal(false)}>Close</Button>
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>
            <Modal size='sm' centered show={showFail} onHide={()=>setFailModal(false)} backdrop="static">
                <Modal.Body>Due to technical difficulties your request could not be sent. Please contact <a href="mailto:info@forrentca.com">info@forrentca.com</a>.</Modal.Body>
                <Modal.Footer>
                <Nav.Link as={Link} to="/"><Button onClick={()=>{setFailModal(false);setValidated(false);}}>Close</Button></Nav.Link>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default ReqInfoForm;